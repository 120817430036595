import React, { useContext } from 'react';
import { Link, graphql } from 'gatsby';
import createLink from 'utils/createLink';
import { get, isEmpty } from 'lodash';
import Helmet from 'react-helmet';

import { RichText } from 'prismic-reactjs';
import { Content } from 'components/content/Content';

export const query = graphql`
query BeerQuery($uid: String) {
  prismic {
    allBeers(uid: $uid) {
      edges {
        node {
          beer_name
          subheading
          description
          beer_type
          image
          accent_image_1
          accent_image_2
          vinbud__link
          untappd_link
        }
      }
    }
  }
}
`;

// title_seo
// description_seo
// image_seo

export default ({ data }) => {
  const doc = data.prismic.allBeers.edges.slice(0, 1).pop();
  if (!doc) {
    return null;
  }

  // const seoTitle = doc.node.title_seo || doc.node.name;
  // const seoDescription = doc.node.description_seo;
  // const seoImage = (doc.node.image_seo && doc.node.image_seo.url) || (doc.node.image && doc.node.image.hero.url);

  return (
    <>
      <Helmet title={RichText.asText(doc.node.beer_name)} />
      <Content
        title={RichText.asText(doc.node.beer_name)}
        subheading={RichText.asText(doc.node.beer_type)}
        description={RichText.render(doc.node.description)}
        image={doc.node.image.url}
        accentImageA={doc.node.accent_image_1 && doc.node.accent_image_1.url}
        accentImageB={doc.node.accent_image_2 && doc.node.accent_image_2.url}
        vinbudLink={doc.node.vinbud__link && RichText.asText(doc.node.vinbud__link)}
        untappdLink={doc.node.untappd_link && RichText.asText(doc.node.untappd_link)}
      />
    </>
  );
};
