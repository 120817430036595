import * as React from 'react';
import { TimelineLite } from 'gsap';
import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Image } from 'components/image/Image';
import { useInView } from 'react-intersection-observer';

import s from './Content.scss';

interface IContentProps {
  title: string;
  subheading: string;
  children: React.ReactNode;
}

export const Content = ({ title, subheading, description, image, vinbudLink, untappdLink, accentImageA, accentImageB, children }: IContentProps) => {
  const refIntro = React.useRef<HTMLDivElement>(null);
  const refContent = React.useRef<HTMLDivElement>(null);
  const refAccentA = React.useRef<HTMLDivElement>(null);
  const refAccentB = React.useRef<HTMLDivElement>(null);

  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-10% 0%'  });

  React.useEffect(() => {
    if (inView) {
      const t = new TimelineLite();

      t.addLabel('start', 0.2);

      if (refIntro.current) {
        t.fromTo(
          refIntro.current,
          1.3,
          { autoAlpha: 0, y: '90px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start',
        );
      }

      if (refContent.current) {
        t.fromTo(
          refContent.current,
          1.3,
          { autoAlpha: 0, y: '90px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=0.2',
        );
      }

      if (refAccentA.current) {
        t.fromTo(
          refAccentA.current,
          1.2,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=0.5',
        );
      }

      if (refAccentB.current) {
        t.fromTo(
          refAccentB.current,
          1.2,
          { autoAlpha: 0, y: '50px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=1.1',
        );
      }
    }
  }, [inView]);

  return (
    <div className={s.content} ref={ref}>
      <div className={s.content__intro} ref={refIntro}>
      <Image
        className={s.content__image}
        src={image}
      />
      </div>

      <div className={s.content__text} ref={refContent}>
        <h2 className={s.content__heading}>{title}</h2>
        <h3 className={s.content__subheading}>{subheading}</h3>
        <div className={s.content__copy}>
          {description}
          {untappdLink && (
            <p>
              <a href={untappdLink} target="_blank">Untappd</a>
            </p>
          )}
          {vinbudLink && (
            <p>
              <a href={vinbudLink} target="_blank">Buy in Vínbúð</a>
            </p>
          )}
        </div>

        <div className={s.content__feed}>
          <div className={s.content__feedRight} ref={refAccentA}>
            {accentImageA && (
              <Image
                className={s.content__feedImg}
                src={accentImageA}
              />
            )}
          </div>
          <div className={s.content__feedLeft} ref={refAccentB}>
            {accentImageB && (
              <Image
                className={s.content__feedImg}
                src={accentImageB}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
